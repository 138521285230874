<template>
  <section>
    <div class="dialogcontainer">
      <div class="contactus">
        <el-dialog
          class="project_trash_dialog"
          :visible.sync="ContactUsVisible"
          width="288px"
          :close-on-click-modal="false"
          :show-close="false"
          style="margin-top: 15vh; padding: 0"
        >
          <div class="title">
            <span class="title_text">暂不支持手机端访问</span>
            <span class="title_text">请前往PC网页端进行注册体验</span>
          </div>
          <div class="dialog_button">
            <div class="close" @click="ContactUsVisible = false">我知道了</div>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="background">
      <img
        class="img"
        src="../../../assets/zh/images-mobile/jihua@2x.png"
        alt=""
      />
      <div class="button">
        <el-button @click="GoCooVally()">立即体验</el-button>
      </div>
    </div>
    <FooterB></FooterB>
  </section>
</template>
      
<script>
import FooterB from "../components/FooterB.vue";

export default {
  name: "Home",
  components: {
    FooterB,
  },

  data() {
    return {
      ContactUsVisible: false,
    };
  },

  methods: {
    GoCooVally() {
      this.ContactUsVisible = true;
    },
  },
};
</script>
    
<style lang="scss" scoped>
.dialogcontainer {
  .contactus {
    /deep/ .el-dialog__header {
      padding: 0;
    }

    /deep/ .el-dialog__body {
      padding: 0;
      margin-top: -10px;
    }

    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 30px;
      text-align: center;
      margin-top: 25px;
      padding-bottom: 10px;
    }
    /deep/ .dialog_button {
      padding-top: 14px;
      .close {
        width: 100%;
        height: 50px;
        padding-top: 13px;
        text-align: center;
        border-top: 1px solid #eaeaf0;
        // background: red;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2b2c3d;
        line-height: 22px;
      }
      .close:hover {
        color: #2b2c3dcc;
      }
    }
  }
}

.background {
  width: 100%;
  // min-width: 1920px;
  // min-height: 3500px;
  //   height: 3575px;
  // background-image: url("../../../assets/zh/images/jihua@2x.png");
  // background-size: 1920px 3575px;
  // background-repeat: no-repeat;
  img {
    width: 100%;
  }

  .button {
    position: absolute;
    // top:410px;
    // left:870px;
    top: 5.4rem;
    left: 3.4rem;
    padding: 0;
    /deep/.el-button {
      width: 16vh;
      height: 6vh;
      border: none;
      background: linear-gradient(180deg, #abdafb 0%, #8bc4fa 100%);
      box-shadow: 0px 11px 24px 0px rgba(0, 82, 194, 0.27);
      border-radius: 11px;
      border: 2px solid rgba(255, 255, 255, 0.74);
      position: relative;
      padding: 0;

      span {
        font-size: 2.5vh;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        font-weight: 600;
        color: #1839b8;
        line-height: 2.5vh;
      }
    }
  }
}
</style>